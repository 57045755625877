
window.onload = function () {

    'use strict';

    var introBlock = document.getElementById('intro-block'),
        loaderBlock = document.getElementById('loader-block'),
        spinner = document.getElementById('spinner'),
        logo = document.getElementById('intro-block__logo'),
        subtitle = document.getElementById('subtitle'),
        languageSwitcher = document.getElementById('language');


    //TweenMax.set(logo, {opacity: 0.3});
    TweenMax.set(subtitle, {opacity: 0, y: '+=30px'});
    TweenMax.set(languageSwitcher, {opacity: 0, y: '+=200px'});

    imagesLoaded(introBlock, { background: true }, function() {

        TweenMax.to([spinner, loaderBlock], 1, {opacity: 0, ease: Expo.easeInOut, onComplete: function () {

            var className = 'is-running';
            if (spinner.classList) {
                spinner.classList.remove(className);
            } else {
                spinner.className = spinner.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }

            loaderBlock.style.display = 'none';

            //TweenMax.to(logo, 1, {opacity: 1, ease:  Expo.easeInOut});
            TweenMax.to(subtitle, 1.4, {opacity: 1, y: '-=30px', ease: Expo.easeOut, delay: 0});
            TweenMax.to(languageSwitcher, 1.4, {opacity: 1, y: '-=200px', ease: Expo.easeInOut, delay: 0.2});
        }});


    });

    // Language hovering
    var links = languageSwitcher.getElementsByTagName('a');
    for(var i=0; i<links.length; i++) {
        var link = links[i];
        link.addEventListener('mouseout', mouseOutLinkHandler);
        link.addEventListener('mouseover', mouseOverLinkHandler);
    }

    function mouseOutLinkHandler(event) {
        event.stopPropagation();
        event.preventDefault();
        var link = event.target;
        var parent = link.parentNode;

        parent.classList.remove('active');
        languageSwitcher.classList.remove('hovered');
        //TweenMax.to(parent, 1, {width: 'auto'});
        //TweenMax.to(parent, 1, {width: 'auto'});
    }

    function mouseOverLinkHandler(event) {
        event.stopPropagation();
        event.preventDefault();
        var link = event.target;
        var parent = link.parentNode;

        parent.classList.add('active');
        languageSwitcher.classList.add('hovered');
        //console.log(languageSwitcher.clientWidth);
        //TweenMax.to(languageSwitcher.childNodes, 1, {width: 0});
        //TweenMax.to(parent, 1, {width: languageSwitcher.clientWidth});
    }

};